import axios from 'axios'

const useCoupon = () => {
  const parkingApiHost = process.env.VUE_APP_API_HOST
  const signUrl = `${parkingApiHost}/cars/NubiWebApi/api/Nubi/SignIn`
  const checkStatusUrl = `${parkingApiHost}/cars/NubiWebApi/api/Nubi/SearchCouponNumber`
  const exchangeParkingCouponUrl = `${parkingApiHost}/cars/NubiWebApi/api/Nubi/GetCouponNumber`
  
  // 以下三個是開發用，還需要去 vue.config.js 設定 proxy
  // const signUrl = `/api/cars/NubiWebApi/api/Nubi/SignIn`
  // const checkStatusUrl = '/api/cars/NubiWebApi/api/Nubi/SearchCouponNumber'
  // const exchangeParkingCouponUrl = `/api/cars/NubiWebApi/api/Nubi/GetCouponNumber`


  const getAccessToken = async () => {
    const info = await getClientInformation()
    const response = await axios.post(signUrl, info)
    return response.data.accessToken
  }

  const exchange = async (data) => {
    const token = await getAccessToken()
    return axios.post(exchangeParkingCouponUrl, {
      ...data,
      accessToken: token
    })
  }

  const checkCouponStatus = async (data) => {
    const token = await getAccessToken()
    return axios.post(checkStatusUrl, {
      ...data,
      accessToken: token
    })
  }

  const getClientInformation = async () => {
    // TODO: 3991 Use env ?
    return {
      "clientId": "bnViaQ==",
      "clientSecret": "UWNTNlRVa2pWWGlaWlIwV3JGYlJRR0I5THBSRm02Wk5MUklCL0RvbFkxWT0yMDI0MDkxMDA5MjIzMw=="
    }
  }

  return {
    exchange,
    checkCouponStatus
  }
}

export default useCoupon