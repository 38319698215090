<template>
  <div class="dialog-wrapper">
    <dialog open class="dialog" @click.prevent="handleDialog">
      <h2 class="title fL">注意事項</h2>
      <ol class="fM">
        <li>折抵券過期後將無法使用，已兌換的雲林幣恕不退回。</li>
        <li>完成兌換即無法返還雲林幣。</li>
        <li>目前「路邊停車」折抵券使用期限為兌換後24小時</li>
      </ol>
      <div class="buttons">
        <button class="button-outline rounded fM" @click="notShowAgain">不再顯示</button>
        <button class="fM rounded" @click="justClose">確認</button>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: 'ParkingNoticeDialog',
  methods: {
    handleDialog(e) {
      e.stopPropagation();
    },
    notShowAgain() {
      localStorage.setItem('notShowParkingNotice', '1');
      this.justClose()
    },
    justClose() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  ol {
    margin-bottom: 0;
  }

  .title {
    text-align: center;
  }
}
</style>