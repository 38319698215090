<template>
  <div class="body fixed mobile" v-show="open">
    <section class="popNoQA popup" ref="popup">
      <div class="popBody dc-popBody">
        <section class=""></section>
        <section class="popMain dc-popMain">
          <div class="fL">{{ title }}</div>
          <span class="fM gray"><img :src="qrcode" /></span>
          <div class="fL">{{ bottomTitle }}</div>
        </section>
        <section class="popFooter dc-popFooter">
          <a class="close dc-close-btn" @click="setEmit('popupOpen', false)">
            <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
          </a>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Popup",
  props: ["open", "title", "bottomTitle", "readercode"],
  data() {
    return {
      qrcode:
        "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
    };
  },
  created() {
    this.getReaderCodeQrCode();
  },
  mounted() {
    // this.$el.addEventListener('click', this.onClick)

    this.popup();
  },
  methods: {
    setEmit(eventName, val) {
      this.$emit(eventName, val);
    },
    popup() {
      let target = this.$refs["popup"];
      target.style.display = "flex";
    },
    async getReaderCodeQrCode() {
      const url = `${this.apiHost}/digital_card/qrcode/v1/merchants/${this.merchantId}/qrcode?readercode=${this.readercode}`;

      this.qrcode = await axios({
        url,
        method: "GET",
      }).then(function (response) {
        return response.data.data;
      });
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>

<style>
.dc-popBody {
  background: transparent !important;
  height: unset !important;
}

.dc-popMain {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 30px !important;
}

.dc-popMain .fL {
  text-align: center;
}

.dc-popFooter {
  justify-content: center !important;
  margin: 10px !important;
}

.dc-close-btn {
  position: relative !important;
  top: unset;
  right: unset;
  background-color: #fff !important;
  color: #000 !important;
  padding: 10px !important;
}
</style>
